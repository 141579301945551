import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const BoxBallance: CSSObject = {
  bgGradient: 'linear(to-b, alpha.800, alpha.900)',
  mx: '0.5',
  borderBottomRadius: 'base',
  '> div:first-of-type': {
    color: 'beta.500',
    '> p': {
      fontSize: 'sm',
      fontWeight: 'medium',
    },
    '> div': {
      '> p': {
        fontSize: 'sm',
      },
    },
  },
  '> div:last-of-type': {
    color: 'white',
    '> p': {
      fontSize: 'sm',
      fontWeight: 'medium',
    },
    '> div': {
      '> p': {
        fontSize: 'sm',
      },
    },
  },
};

export const buttonDepositProps: CustomButtonProps = {
  variant: 'solid',
  textShadow: '0px 0px 8px rgba(67, 187, 255, 0.50)',
  fontWeight: 'normal',
  w: 'full',
  mt: '2',
  sx: {
    '& svg': {
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.30)',
      mb: '1',
    },
  },
};

export const ButtonNavItem = (): CSSObject => ({
  bg: 'white',
  color: 'gray.700',
  borderTop: '1px',
  borderColor: 'gamma.200',

  '&& > p': { transition: 'all 0.2s ease-in-out' },
  ':first-child': {
    borderTop: 'none',
  },
  '&:hover, &:active': {
    bg: 'gray.100',
  },
});

export const PopoverHeaderQA: CSSObject = {
  bg: 'alpha.800',
  mx: '0.5',
  mt: '0.5',
  border: 'none',
  borderTopRadius: 'base',
  color: 'white',
  lineHeight: 'normal',
};

export const PopoverContentQA: CSSObject = {
  bg: 'white',
  boxShadow: '2xl',
  _focus: {
    boxShadow: '2xl',
  },
  top: '-2.5',
};

export const FlexBallanceItem: CSSObject = {
  color: 'white',

  p: {
    _last: { fontWeight: 'extrabold' },
  },
};

export const TextBalanceValue: CSSObject = {
  color: 'alpha.500',
  textShadow: '0px 2px 3px rgba(0, 0, 0, 0.2)',
  fontSize: 'sm',
  fontWeight: 'extrabold',
};

export const TextQuickAccessHello: CSSObject = {
  fontSize: 'sm',
};

export const buttonBalanceProps: CustomButtonProps = {
  variant: 'unstyled',
  display: 'flex',
  fontSize: 'sm',
  h: 'unset',
};
