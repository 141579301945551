import { BoxProps, ButtonProps, CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';
import headerLogo from '@/assets/templebet/images/logo/header-logo.png';

export const ButtonOutsideArea = {
  boxShadow: 'none',
};

export const FlexContainer = () => ({
  'body[data-sensitive="true"] &': {
    pos: ['relative', null, 'unset'],
  },

  w: ['100%', null, 'unset'],
  h: ['auto', null, null, null],
  pt: ['0', null, null, '2', 'unset'],
  pb: '0',
  px: ['2', '2', '2', '0'],
});

export const ButtonGroup: CSSObject = {
  pb: ['0', null, '0'],
  pos: 'relative',
  gap: '1',
  minH: '45px',
  ml: '-4',
  mb: ['0', '-4'],
  flexWrap: 'wrap',
  justifyContent: ['flex-end', 'space-between'],
};

export const ButtonGroupBets: CSSObject = {
  gap: '1',
  ml: '0',
  '>*:first-of-type:not(:last-of-type)': {
    borderRadius: 'lg',
  },
  '>*:not(:first-of-type):last-of-type': {
    borderRadius: 'lg',
    bgGradient:
      'linear-gradient(180deg, var(--bc-colors-alpha-500) 0%, var(--bc-colors-alpha-600) 100%)',
    boxShadow:
      '0px 4px 6px 0px var(--bc-colors-blackAlpha-500), 0px 0px 0px 2px var(--bc-colors-beta-400) inset, 0px 0px 0px 4px #20273f inset, 0px 0px 0px 5px var(--bc-colors-whiteAlpha-400) inset',
    _hover: {
      bgGradient:
        'linear-gradient(180deg, var(--bc-colors-alpha-800) 0%, var(--bc-colors-alpha-800) 100%)',
      boxShadow:
        '0px 0px 0px 2px var(--bc-colors-beta-400) inset, 0px 0px 0px 3.5px var(--bc-colors-alpha-700) inset',
    },
    _focus: {
      bgGradient:
        'linear-gradient(180deg, var(--bc-colors-alpha-900) 0%, var(--bc-colors-alpha-900) 100%)',
      boxShadow:
        '0px 2px 4px 0px var(--bc-colors-blackAlpha-50) inset,0px 1px 0px 0px var(--bc-colors-whiteAlpha-50)',
    },
  },
};

export const buttonBetSlipProps: ButtonProps = {
  variant: 'solid',
  pt: '10px',
  pb: '10px',
  pr: '0',
  pl: '0',
  lineHeight: 'normal',
  transition: 'all .3s ease-in-out',
  _hover: {
    boxShadow:
      '0px 0px 0px 2px var(--bc-colors-beta-500) inset, 0px 0px 0px 3px var(--bc-colors-alpha-800) inset, 0px 0px 0px 4px var(--bc-colors-blackAlpha-400) inset',
  },
  sx: {
    '&&': {
      ml: 0,
    },

    '.btn-span': {
      flexDir: 'column',
      gap: 0,
      alignSelf: 'center',
      lineHeight: 1,

      p: {
        fontSize: '15px',

        _first: {
          fontFamily: 'body',
          fontWeight: 'bold',
          fontSize: '2xs',
          color: 'white',
        },
      },
    },
  },
};

export const buttonBetsProps: ButtonProps = {
  variant: 'solid',
  boxSize: '12',
  lineHeight: 'normal',
  sx: {
    '.btn-span': {
      flexDir: 'column',
      gap: 0,
      alignSelf: 'center',
      lineHeight: 1,
    },
    '.span-bet': {
      fontFamily: 'body',
      fontWeight: 'normal',
      fontSize: '10px',
      color: 'white',
    },

    '.span-bet-count': {
      fontSize: 'sm',
    },
  },
};

export const IconButtonMenu = (): CSSObject => ({
  bg: 'transparent',
  color: 'beta.500',
  h: '30px',
  ml: '-1',
  mr: '1',
  '& svg': {
    boxSize: ['9', '8'],
    color: 'beta.500',
  },
});

export const LinkLogo: CSSObject = {
  w: ['145px', null, '10px'],
  height: '60px',
  mt: ['1.5', null, null, '4'],
  ml: '0',
  display: 'block',
  a: {
    display: [null, null, null, 'none'],
    img: {
      w: '140px',
    },
  },
  _after: {
    content: '""',
    backgroundImage: `url('${headerLogo}')`,
    display: ['none', null, null, null, 'block'],
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    height: '135px',
    width: '149px',
    position: 'relative',
    bottom: '6',
    left: '9px',
  },
};

export const FlexButtonGroup: CSSObject = {
  pb: ['0', null, '0'],
  pos: 'relative',
};

export const TextButtonNumber: CSSObject = {
  fontSize: 'lg',
};

export const TextButton: CSSObject = {
  color: 'white',
  fontSize: '2xs',
};

export const ButtonLogIn: ButtonProps = {
  border: '0',
  color: 'beta.500',
  variant: 'solid',
  h: '46px',
  backgroundImage:
    'linear-gradient(180deg, var(--bc-colors-alpha-500) 0%, var(--bc-colors-alpha-600) 100%)',
  boxShadow:
    '0px 4px 6px 0px var(--bc-colors-blackAlpha-500), 0px 0px 0px 2px var(--bc-colors-beta-400) inset, 0px 0px 0px 4px #20273f inset, 0px 0px 0px 5px var(--bc-colors-whiteAlpha-400) inset',
  _hover: {
    color: 'beta.500',
    bg: 'alpha.800',
    boxShadow:
      '0px 0px 0px 2px var(--bc-colors-beta-400) inset,0px 0px 0px 3.5px var(--bc-colors-alpha-700) inset',
  },
};
export const ButtonSignUp: ButtonProps = {
  variant: 'solid',
  h: '46px',
  backgroundImage:
    'linear-gradient(180deg, var(--bc-colors-alpha-500) 0%, var(--bc-colors-alpha-600) 100%)',
  boxShadow:
    '0px 4px 6px 0px var(--bc-colors-blackAlpha-500), 0px 0px 0px 2px var(--bc-colors-beta-400) inset, 0px 0px 0px 4px #20273f inset, 0px 0px 0px 5px var(--bc-colors-whiteAlpha-400) inset',
  _hover: {
    color: 'beta.500',
    bg: 'alpha.800',
    backgroundImage:
      'linear-gradient(180deg, var(--bc-colors-alpha-800) 0%, var(--bc-colors-alpha-800) 100%)',
    boxShadow:
      '0px 0px 0px 2px var(--bc-colors-beta-400) inset,0px 0px 0px 3.5px var(--bc-colors-alpha-700) inset',
  },
};

export const ButtonBetSlip: CSSObject = {
  height: '46px',
  px: ['2', '3'],
  border: '0',
  bgGradient:
    'linear-gradient(180deg, var(--bc-colors-alpha-500) 0%, var(--bc-colors-alpha-600) 100%)',
  boxShadow:
    '0px 4px 6px 0px var(--bc-colors-blackAlpha-500), 0px 0px 0px 2px var(--bc-colors-beta-400) inset, 0px 0px 0px 4px #20273f inset, 0px 0px 0px 5px var(--bc-colors-whiteAlpha-400) inset',
  color: 'beta.500',
  pt: '3',
  '.btn-span': {
    flexDir: 'column',
    gap: '0',
  },
  _hover: {
    bgGradient:
      'linear-gradient(180deg, var(--bc-colors-alpha-800) 0%, var(--bc-colors-alpha-800) 100%)',
    color: 'beta.500',
    boxShadow:
      '0px 0px 0px 2px var(--bc-colors-beta-400) inset,0px 0px 0px 3.5px var(--bc-colors-alpha-700) inset',
  },
  _active: {
    boxShadow: '0px 1px 0px 0px var(--bc-colors-whiteAlpha-400)',
    bgGradient:
      'linear-gradient(180deg, var(--bc-colors-alpha-900) 0%, var(--bc-colors-alpha-900) 100%)',
  },
  _focus: {
    bgGradient:
      'linear-gradient(180deg, var(--bc-colors-alpha-900) 0%, var(--bc-colors-alpha-900) 100%)',
  },
};

export const buttonQuickAccessProps: CustomButtonProps = {
  px: ['2', '1.5'],
  py: '1',
  sx: {
    '&&': {
      height: '45px',
      minW: 'auto',
      bgGradient:
        'linear-gradient(180deg, var(--bc-colors-alpha-500) 0%, var(--bc-colors-alpha-600) 100%)',
      boxShadow:
        ' 0px 4px 6px 0px var(--bc-colors-blackAlpha-500), 0px 0px 0px 2px var(--bc-colors-beta-400) inset, 0px 0px 0px 4px #20273f inset, 0px 0px 0px 5px var(--bc-colors-whiteAlpha-400) inset',
      bgSize: '100% 100%',
      alignItems: 'flex-start',
      animation: '.5s ease-in-out',
      svg: {
        mr: '2px',
        pos: 'relative',
        top: 'px',
        _active: {
          color: 'beta.800',
        },
      },
      '&:active': {
        boxShadow: '0px 1px 0px 0px var(--bc-colors-whiteAlpha-400)',
        backgroundImage:
          'linear-gradient(180deg, var(--bc-colors-alpha-900) 0%, var(--bc-colors-alpha-900) 100%)',
      },

      '&:hover': {
        bgPos: 'bottom center',
        bgSize: '250% 250%',
        color: 'beta.500',
        bgGradient:
          'linear-gradient(180deg, var(--bc-colors-alpha-800) 0%, var(--bc-colors-alpha-800) 100%)',
        boxShadow:
          '0px 0px 0px 2px var(--bc-colors-beta-400) inset,0px 0px 0px 3.5px var(--bc-colors-alpha-700) inset',
      },
      '&[aria-expanded="true"]': {
        bg: 'white',
        boxShadow: 'none',
        borderBottomLeftRadius: '0',
        borderBottomRightRadius: '0',
        '.chevron-icon': { color: 'beta.800' },
        '.span-balance': {
          color: 'delta.600',
          textShadow: 'none',
        },
        '.account-text': {
          color: 'beta.500',
          textShadow: 'none',
        },
        '.span-bonus': {
          fontFamily: 'body',
          color: 'beta.800',
          fontWeight: 'bold',
          textShadow: 'none',
        },
      },

      '&:after': { bg: 'white' },

      '.btn-span': { flexDir: 'column', alignItems: 'flex-start' },
      '.span-balance': { color: 'beta.400', fontSize: 'md', ml: '1' },
      '.span-bonus': {
        fontFamily: 'body',
        color: 'white',
        fontWeight: 'bold',
        fontSize: '2xs',
        ml: '1',
      },
      '.chevron-icon': { color: 'beta.500', ml: '0' },
      '.account-text': { color: 'beta.500' },
    },
  },
};

export const ButtonBets: CSSObject = {
  h: '46px',
  bgGradient:
    'linear-gradient(180deg, var(--bc-colors-alpha-500) 0%, var(--bc-colors-alpha-600) 100%)',
  boxShadow:
    '0px 4px 6px 0px var(--bc-colors-blackAlpha-500), 0px 0px 0px 2px var(--bc-colors-beta-400) inset, 0px 0px 0px 4px #20273f inset, 0px 0px 0px 5px var(--bc-colors-whiteAlpha-400) inset',
  borderRadius: 'lg',
  fontWeight: 'normal',
  pt: '3',
  '.btn-span': {
    color: 'white',
    flexDir: 'column',
    gap: '0',
  },
  '.span-bet-count': {
    color: 'beta.500',
    fontSize: 'lg',
  },
  '.span-bet': {
    fontWeight: 'normal',
    fontSize: '2xs',
  },
  _hover: {
    bgGradient:
      'linear-gradient(180deg, var(--bc-colors-alpha-800) 0%, var(--bc-colors-alpha-800) 100%)',
    boxShadow:
      '0px 0px 0px 2px var(--bc-colors-beta-400) inset, 0px 0px 0px 3.5px var(--bc-colors-alpha-700) inset',
  },
  _focus: {
    bgGradient:
      'linear-gradient(180deg, var(--bc-colors-alpha-900) 0%, var(--bc-colors-alpha-900) 100%)',
    boxShadow:
      '0px 2px 4px 0px var(--bc-colors-blackAlpha-50) inset,0px 1px 0px 0px var(--bc-colors-whiteAlpha-50)',
  },
};

export const BoxWrapper: BoxProps = {
  mb: '1',
};
