import { MiniBetSlipSchema } from './MiniBetSlip.styles';

export const miniBetSlipStyles: MiniBetSlipSchema = {
  wrapper: {
    mx: ['0', '-4'],
    bottom: ['15px', null, '0'],
    left: '0',
    position: ['fixed', null, null, 'sticky'],
    right: '0',
    zIndex: 'sticky',
    bg: 'beta.200',
    borderBottomRadius: 'md',
  },
  slideBox: {
    bg: 'blackAlpha.400',
    color: 'gamma.200',
    mb: '-7',
    borderBottomRadius: 'md',
  },
  accordionItem: {
    borderTop: '3px',
    borderColor: 'gamma.500',
    borderTopWidth: '0',
    _last: {
      borderBottomWidth: '0',
    },
  },
  accordionHeaderText: {
    boxShadow:
      '0 1px rgba(255, 255, 255, .15), 0 1px rgba(255, 255, 255, .15) inset',
  },
  accordionButton: {
    bg: 'beta.200',
    fontSize: 'sm',
    fontWeight: 'normal',
    fontFamily: 'heading',
    gap: '1',
    px: '3',
    py: '2',
    color: 'beta.800',
    borderTop: '4px',
    borderColor: 'beta.900',
    _hover: {
      bg: 'beta.200',
    },
  },
  accordionButtonIcon: {
    fontSize: 'sm',
    color: 'beta.600',
  },
  buttonsHStack: {
    gap: '0',
  },
  headerStack: {
    bg: 'beta.800',
    borderRadius: 'base',
    bottom: '100%',
    fontSize: 'xs',
    fontWeight: 'medium',
    left: '50%',
    pos: 'absolute',
    px: '4',
    py: '2',
  },
  headerText: {
    flex: '1',
    color: 'white',
    lineHeight: 'normal',
  },
  headerButton: {
    display: 'inline-flex',
    fontSize: 'xl',
    minW: '0',
  },
  accordionPanel: {
    bg: 'alpha.800',
    fontSize: 'xs',
    maxH: '32',
    overflow: 'auto',
    p: '0',
  },
  selectionFlex: {
    borderColor: 'rgba(0, 0, 0, 0.10)',
    bg: 'beta.300',
    gap: '2',
    p: '2',
    pos: 'relative',
    _notFirst: {
      borderTopStyle: 'dashed',
      borderTopWidth: '1px',
    },
    _before: {
      border: 'inherit',
      borderColor: 'whiteAlpha.300',
      content: '""',
      h: '1px',
      left: '0',
      pos: 'absolute',
      right: '0',
      top: '0',
    },
  },
  selectionMarketName: {
    flex: '1',
    fontWeight: 'bold',
    color: 'beta.800',
  },
  selectionPropositionName: {
    flex: ['1', null, '2', null, '3'],
    color: 'beta.800',
  },
  footerStack: {
    bg: '#B4924E',
    direction: 'row',
    fontSize: 'sm',
    fontWeight: 'semibold',
    py: '2.5',
    px: '4',
    pos: 'relative',
    borderTopColor: 'blackAlpha.300',
    borderBottomRadius: 'base',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.15) inset',
    _before: {
      opacity: '0.2',
      bottom: 'calc(100% + 1px)',
      content: '""',
      h: '4',
      left: '0',
      pointerEvents: 'none',
      pos: 'absolute',
      right: '0',
      transitionDuration: 'normal',
      transitionProperty: 'common',
    },
  },
  footerHStack: {
    flex: '1',
  },
  legsText: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    color: 'beta.900',
    fontWeight: 'extrabold',
    textTransform: 'uppercase',
    fontSize: '2xs',
  },
  legsChunks: {
    color: 'delta.700',
    fontFamily: 'heading',
    fontSize: '2xl',
    fontWeight: 'normal',
  },
  oddsText: {
    color: 'beta.900',
    textTransform: 'uppercase',
    flex: '3',
    display: 'flex',
    fontWeight: 'bold',
    flexDirection: 'column',
    fontSize: '2xs',
  },
  oddsSkeletonLoader: {
    display: 'inline-block',
    ml: '1',
    w: '8',
  },
  oddsChunks: {
    color: 'delta.700',
    fontFamily: 'heading',
    fontSize: '2xl',
    fontWeight: 'normal',
  },
  clearButton: {
    variant: 'outline',
  },
  addToSlipButton: {
    variant: 'solid',
    fontSize: 'sm',
    px: '3',
  },
};
