import { CSSObject } from '@chakra-ui/react';

export const TextRaceNumber: CSSObject = {
  flex: 0,
  py: '1',
  fontWeight: 'bold',
  textAlign: 'center',
  minWidth: '6',
  display: 'inline-block',
  fontSize: '2xs',
  h: '20px',
  bg: 'delta.500',
  borderRadius: 'base',
  color: 'delta.900',
  boxShadow: '0px 2px 4px 0px var(--bc-colors-blackAlpha-400)',
};

export const LinkRaceContainer: CSSObject = {
  bg: 'linear-gradient(180deg, #FFF1CB, var(--bc-colors-beta-200))',
  bgColor: 'beta.100',
  color: 'beta.800',
  borderRadius: 'md',
  boxShadow: '0px 4px 4px 0px var(--bc-colors-blackAlpha-400)',
  mb: '2.5',
  transition: 'all .3s ease-in-out',
  '.chakra-badge': {
    boxShadow: '0px 2px 4px 0px var(--bc-colors-blackAlpha-400)',
  },
};

export const TextRaceType: CSSObject = {
  fontFamily: 'heading',
  color: 'beta.500',
  fontSize: 'md',
  textTransform: 'uppercase',
  fontWeight: 'normal',
};

export const TextRace: CSSObject = {
  fontFamily: 'heading',
  color: 'beta.800',
  fontWeight: 'normal',
  fontSize: 'md',
  textTransform: 'uppercase',
};

export const Icon: CSSObject = {
  boxSize: ['4'],
  color: 'beta.800',
};

export const BoxColumn: CSSObject = {
  mb: ['0', 'unset'],
};
