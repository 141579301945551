import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import Betslip from '../../../components/Betslip/Betslip';
import NextToJumpBanner from '../../../components/NextToJumpBanner/NextToJumpBanner';
import { TTemplate } from '@/helpers/createTemplate';
import SideNav from '@/components/SideNav/SideNav';
import Footer from '@/components/Footer/Footer';
import Toggle from '@/views/Home/conditionals/Toggle/template.buffalobet';
import Header from '@/components/Headers/HeaderPrimary/Header';
import Logo from '../components/Logo/Logo';
import ResponsibleGamblingBanner from '@/components/ResponsibleGamblingBanner/ResponsibleGamblingBanner';
import { styles } from '../styles/primary.styles';

const Component = () => (
  <Box {...styles.grid}>
    <Box {...styles.ntj}>
      <NextToJumpBanner />
    </Box>

    <Box {...styles.sidenav}>
      {/* TODO: Find out where the large width is coming from */}
      <Box>
        <Box className="logo-toggle-wrap">
          <Logo />
          <Toggle />
        </Box>
        <SideNav />
      </Box>
    </Box>

    <Box {...styles.main}>
      <Outlet />
    </Box>

    <Box {...styles.qam}>
      <Header />
    </Box>

    <Box {...styles.aside}>
      <Betslip />
    </Box>

    <Box {...styles.footer}>
      <Footer />
    </Box>

    <Box {...styles.message}>
      <ResponsibleGamblingBanner />
    </Box>
  </Box>
);

const template = (): TTemplate => ({
  wrapper: <></>,
  header: <></>,
  main: <Component />,
  responsibleGamblingMessage: <></>,
});

export default template;
