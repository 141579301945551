import { CSSObject, TextProps } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';
import { TIndex } from '@/components/FormElements/Input';

export const buttonDepositsAvailableProps: CustomButtonProps = {
  variant: 'primary',
};
export const DepositContainer: CSSObject = {
  mb: '2',
};
export const DefaultText: CSSObject = {
  color: 'beta.800',
  fontSize: 'sm',
  fontWeight: 'semibold',
};
export const ImageCardLogo: CSSObject = {
  bg: 'white',
  border: '1px',
  borderColor: 'gray.300',
  borderRadius: 'base',
  p: '1',
  w: '12',
  h: '7',
};
export const RowDivider: CSSObject = {
  borderColor: 'whiteAlpha.300',
  borderStyle: 'dashed',
};
export const FlexWrapper: CSSObject = {
  py: '3',
  '.chakra-radio': {
    mr: 'auto',
  },
};
export const TextLastFour: CSSObject = {
  color: 'beta.800',
  fontSize: 'sm',
  fontWeight: 'medium',
  ml: '3',
};
export const BadgeWrapper: CSSObject = {
  alignSelf: 'center',
  fontSize: 'xs',
  ml: '4',
  p: '1',
};
export const BoxCardsContainer: CSSObject = {
  mb: '2',
};
export const BoxInputContainer: CSSObject = {
  mb: '3',
  '.chakra-form-control': {
    mb: '0',
  },
  '.chakra-form__label': {
    color: 'beta.800',
  },
  '.chakra-input__group': {
    borderRadius: 'md',
    borderWidth: '2px, 0px, 2px, 2px',
    borderStyle: 'solid',
    borderColor: 'beta.500',
    _hover: {
      borderColor: 'beta.500',
    },
  },
  '.chakra-input__left-addon': {
    fontWeight: 'bold',
    fontSize: 'sm',
    color: 'beta.900',
    bg: 'beta.400',
    h: '38px',
    w: '33px',
    border: 'none',
    borderLeftTopRadius: 'lg',
    borderLeftBottomRadius: 'lg',
  },
  '.chakra-form__error-message': {
    bg: 'orange.500',
    shadow:
      '0px 2px 4px 0px var(--bc-colors-blackAlpha-400), 0px 1px 0px 0px var(--bc-colors-whiteAlpha-400) inset',
    color: 'white',
    h: '20px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '2xs',
    borderRadius: '3px',
    px: '2',
    py: '1',
    svg: {
      fontSize: 'sm',
    },
  },
};

export const depositInputProps: Partial<TIndex> = {
  sxWrapper: {
    '&&': {
      '.chakra-input': {
        color: 'white',
        fontWeight: 'bold',
        borderRightRadius: 'md',
        border: '2px solid',
        borderColor: 'beta.400',
        borderLeft: 'none',
        borderLeftRadius: '0',
        bg: 'beta.900',
        fontSize: 'sm',
        boxShadow: 'none',
        _hover: {
          bg: 'beta.700',
        },
        _focus: {
          bg: 'beta.700',
          boxShadow: 'none',
        },
        _placeholder: {
          color: 'white',
          fontSize: 'sm',
          fontWeight: 'bold',
        },
      },
    },
  },
};

export const IncreaseAmountContainer: CSSObject = {
  mb: '.5',
};
export const TextSSL: CSSObject = {
  color: 'beta.800',
  fontSize: 'xs',
};
export const IconSSL: CSSObject = {
  boxSize: '4',
  mx: '1',
};
export const RadioCard: CSSObject = {
  borderColor: 'gray.300',
  bg: 'white',
  h: '4',
  w: '4',
  boxShadow: '0px 0px 0px 1px var(--bc-colors-gray-300)',
  '&[data-checked]': {
    boxShadow: '0px 0px 0px 1px var(--bc-colors-beta-800)',
    bg: 'beta.800',
    borderColor: 'white',
    _hover: {
      bg: 'beta.800',
      borderColor: 'white',
    },
    _before: {
      display: 'none',
      content: 'unset',
    },
  },
};
export const bannerHeaderProps: TextProps = {
  fontSize: 'sm',
  fontWeight: 'semibold',
  lineHeight: '14.4px',
  color: 'beta.800',
};
export const bannerTextProps: TextProps = {
  fontSize: 'xs',
  color: 'beta.800',
  mt: '0.5',
  fontWeight: 'medium',
};
export const buttonDepositProps: CustomButtonProps = {
  variant: 'odds',
  fontWeight: 'normal',
  fontFamily: 'accent',
  textShadow: '0px 4px 4px 0px var(--bc-colors-whiteAlpha-400)',
};
