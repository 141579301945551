import { CSSObject } from '@chakra-ui/react';

export const FlexBannerWrapper: CSSObject = {
  px: ['2', null, null, '4'],
  pt: '2',
  pb: 0,
  m: 'auto',
  h: '38px',
  w: 'container.xl',
};

export const FlexItemsWrapper: CSSObject = {
  mr: '0',
  '& > div:last-of-type': { mr: '0' },
  gap: '1',
};

export const LinkItem: CSSObject = {
  bg: 'linear-gradient(180deg, var(--bc-colors-alpha-500) 0%, var(--bc-colors-alpha-600) 100%)',
  borderRadius: '5px',
  px: '1.5',
  py: 0,
  alignItems: 'center',
  boxShadow:
    '0px 0px 0px 1px var(--bc-colors-beta-500) inset, 0px 0px 0px 2px var(--bc-colors-alpha-800) inset',
  transition: 'none',
  _hover: {
    bg: 'alpha.800',
    boxShadow:
      '0px 0px 0px 1px var(--bc-colors-beta-500) inset, 0px 0px 0px 2px var(--bc-colors-alpha-800) inset',
  },
  '&&': {
    '.chakra-badge': {
      pos: 'static',
      borderRadius: 'base',
      px: '1',
      h: '4',
      lineHeight: '4',
      fontSize: '2xs',
      ml: '1',
    },

    '.state-ended': {
      color: 'white',
    },

    '.state-inprogress': {
      bg: 'alpha.500',
      color: 'beta.500',
    },

    '.state-prior': {
      color: 'gray.500',
    },
  },
};

export const FlexDetails: CSSObject = {
  display: 'block',
  color: 'beta.500',
  maxW: '90px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  flex: 1,
  fontSize: '2xs',
  lineHeight: 'normal',
};

export const RaceNumber: CSSObject = {
  color: 'delta.900',
  borderRadius: 'base',
  h: '4',
  lineHeight: '17px',
  px: '1',
  bg: 'delta.500',
  fontSize: '2xs',
  order: 3,
  ml: '1',
};

export const Icon: CSSObject = {
  color: 'beta.500',
  filter: 'drop-shadow(0px 2px 1px var(--bc-colors-blackAlpha-300))',
  h: '3',
  w: '3.5',
};

export const FlexButtonContainer: CSSObject = {
  bg: 'transparent',
  pos: 'relative',
  p: '0',
  pl: '0',
  gap: '1',
};

export const ButtonChevron: CSSObject = {
  minW: '6',
  h: 'full',
  aspectRatio: 1,
  bg: 'linear-gradient(180deg, var(--bc-colors-alpha-500) 0%, var(--bc-colors-alpha-600) 100%)',
  color: 'beta.500',
  p: '0',
  borderRadius: 'md',
  border: 'none',
  backdropFilter: 'blur(2.5px)',
  boxShadow:
    '0px 0px 0px 1px var(--bc-colors-beta-500) inset, 0px 0px 0px 2px var(--bc-colors-alpha-800) inset',

  '& > svg': {
    boxSize: '5',
    filter: 'drop-shadow(0px 2px 1px var(--bc-colors-blackAlpha-300))',
  },

  _hover: {
    bg: 'linear-gradient(180deg, var(--bc-colors-alpha-500) 0%, var(--bc-colors-alpha-600) 100%)',
  },
  _focus: {
    boxShadow:
      '0px 0px 0px 1px var(--bc-colors-alpha-400) inset, 0px 0px 0px 2px var(--bc-colors-alpha-800) inset',
  },
};
