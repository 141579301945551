import { CSSObject, ButtonProps } from '@chakra-ui/react';
import { TTableText } from '../../../types';

export const TableItem: CSSObject = {
  fontWeight: 'bold',
  fontSize: 'xs',
  borderRight: 'none',
  color: 'beta.800',
  pl: 0,
  py: 2,
  mb: '4',
  borderBottom: '1.5px dashed',
  borderBottomColor: 'whiteAlpha.500',
  borderStyle: 'dashed',
  position: 'relative',
  _after: {
    content: '""',
    position: 'absolute',
    left: '0',
    bottom: '0',
    width: 'full',
    borderBottom: '1.5px',
    borderColor: 'blackAlpha.50',
    borderStyle: 'dashed',
  },
};
export const TableHeadingText: CSSObject = {
  color: 'beta.800',
};
export const TransactionWrapper: CSSObject = {
  h: 'auto',
};
export const TableText = ({ textVariation }: TTableText): CSSObject => ({
  color: textVariation === 'positive' ? 'delta.600' : 'beta.800',
  fontSize: 'xs',
});
export const NoTransactionsText: CSSObject = {
  color: 'beta.800',
  fontWeight: 'bold',
};
export const buttonMoreTableProps: ButtonProps = {
  color: 'beta.500',
  fontSize: 'sm',
  fontWeight: 'hairline',
  py: '2',
  px: '4',
  bgGradient:
    'linear-gradient(to bottom, var(--bc-colors-beta-700), var(--bc-colors-beta-800))',
  bgImage: 'none',
  textShadow: '0px 4px 4px var(--bc-colors-blackAlpha-400)',
  fontFamily: 'accent',
  borderRadius: 'md',
  boxShadow:
    '0px 3px 4px 0px var(--bc-colors-blackAlpha-300), 0px 0px 0px 1px var(--bc-colors-beta-900) inset, 0px 0px 0px 2px var(--bc-colors-whiteAlpha-500) inset',
  _hover: {
    bgImage:
      'linear-gradient(to bottom, var(--bc-colors-delta-400), var(--bc-colors-delta-500))',
    color: 'white',
  },
  _focus: {
    bgImage:
      'linear-gradient(to bottom, var(--bc-colors-beta-900), var(--bc-colors-beta-900))',
    boxShadow: '0px 2px 0px 0px var(--bc-colors-whiteAlpha-500)',
  },
};
export const BoxTransactionsContainer: CSSObject = {
  ml: '1',
};
