import { BoxProps, ButtonProps, CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const ButtonOutsideArea = {
  display: 'none',
};

export const FlexContainer = (): CSSObject => ({
  px: ['2.5', null, null, '0'],
  h: 'unset',
});

export const BoxWrapper: BoxProps = {
  pr: '0',
};

export const buttonLogInProps: ButtonProps = {
  variant: 'solid',
  height: '46px',
};

export const buttonSignUpProps: CustomButtonProps = {
  variant: 'primary',
  height: '46px',
};

export const buttonQuickAccessProps: CustomButtonProps = {
  variant: 'solid',
  height: '46px',
  p: '2.5',
  sx: {
    ':hover': {
      '.span-balance': {
        color: 'alpha.900',
      },
      '.span-bonus': {
        color: 'alpha.900',
      },
      '.chevron-icon': {
        color: 'alpha.900',
      },
    },
    '.btn-span': {
      flexDir: 'column',
      zIndex: '9',
      textTransform: 'uppercase',
      fontWeight: 'normal',
      mr: '1',
      gap: '0',
      mt: '0.5',
    },
    '.span-balance': {
      fontSize: 'md',
      lineHeight: '14px',
      color: 'beta.500',
      textShadow: '0px 0px 8px rgba(251, 201, 21, 0.50)',
    },
    '.span-bonus': {
      fontFamily: 'body',
      fontSize: '2xs',
      lineHeight: '10px',
      fontWeight: 'extrabold',
      color: 'white',
      alignSelf: 'start',
    },

    'svg:not(.chevron-icon)': {
      pos: 'relative',
      top: '0.5',
      mr: '0.5',
    },
    '.chevron-icon': {
      pos: 'absolute',
      right: '1.5',
      top: '0',
      bottom: '0',
      margin: 'auto 0',
      color: 'alpha.100',
    },

    '&&[aria-expanded="true"]': {
      borderBottomRadius: '0',
      borderTop: '2px',
      borderLeft: '2px',
      borderRight: '2px',
      borderBottom: 'none',
      borderColor: 'white',
      bg: 'white',
      boxShadow: 'none',
      textShadow: '0px 0px 8px rgba(251, 201, 21, 0.50)',

      zIndex: 'dropdown',
      '.chevron-icon': { transform: 'rotate(180deg)', color: 'alpha.500' },
      '.span-balance': {
        color: 'alpha.500',
      },
      '.span-bonus': {
        color: 'alpha.500',
      },
      ':hover': {
        '.span-balance': {
          color: 'alpha.500',
        },
        '.span-bonus': {
          color: 'alpha.500',
        },
        '.chevron-icon': {
          color: 'alpha.500',
        },
      },
      ':after': {
        opacity: '0',
      },
    },
  },
};

export const buttonBetsProps: ButtonProps = {
  variant: 'solid',
  color: 'white',
  height: '46px',
  padding: '0px 5px',
  pt: '12px',
  pb: '13px',
  sx: {
    '.btn-span': {
      lineHeight: '8px',
      flexDir: 'column',
      zIndex: '9',
      textTransform: 'uppercase',
      gap: '1.5',
    },
    '.span-bet': {
      fontWeight: 'normal',
      fontSize: 'xs',
      fontFamily: 'Staatliches',
    },
    '.span-bet-count': {
      fontWeight: 'normal',
      fontSize: 'lg',
      color: 'beta.500',
    },
    ':hover': {
      '.span-bet-count': {
        color: 'alpha.900',
      },
    },
  },
};

export const buttonBetSlipProps: ButtonProps = {
  variant: 'solid',
  color: 'alpha.800',
  height: '46px',
  padding: '0px 5px',
  pt: '12px',
  pb: '13px',

  sx: {
    '.btn-span': {
      lineHeight: '8px',
      flexDir: 'column',
      zIndex: '9',
      textTransform: 'uppercase',
      gap: '1.5',
    },
    ':hover': {
      '.chakra-text': {
        color: 'alpha.900',
      },
    },
  },
};

export const IconButtonMenu = (): CSSObject => ({
  bg: 'transparent',
  color: 'beta.500',
  boxSize: '8',
  ml: '-1',

  '& svg': {
    boxSize: '8',
  },
});

export const FlexLeftGroup: CSSObject = {
  gap: '1.5',
  justifyContent: 'flex-start',
  alignItems: 'center',
};

export const buttonQuickAccess: ButtonProps = {
  variant: 'secondary',
};

export const ButtonGroupBets: CSSObject = {
  gap: '0.5',

  '> .chakra-button ~ .chakra-button': {
    marginInlineStart: 0,
  },
};

export const ButtonBetSlip: CSSObject = {
  ml: '0',
};

export const ButtonGroup: CSSObject = {
  gap: '1',
};

export const LinkLogo: CSSObject = {
  display: ['block', null, null, 'none'],
  ml: '-2',
};

export const TextButton: CSSObject = {
  fontWeight: 'normal',
  fontSize: 'xs',
  fontFamily: 'Staatliches',
  color: 'white',
};

export const TextButtonNumber: CSSObject = {
  fontWeight: 'normal',
  fontSize: 'lg',
  color: 'beta.500',
};
