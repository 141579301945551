import { CSSObject } from '@chakra-ui/react';

export const LinkWrapper: CSSObject = {
  pl: '3',
  py: '3',
  pr: '2',
  mb: '2',
  borderRadius: 'xl',
  bg: 'beta.100',
  boxShadow:
    '0px 3px 4px 0px var(--bc-colors-blackAlpha-300), 0px 0px 0px 3px white inset,0px 0px 0px 5px var(--bc-colors-beta-200) inset',
  gridTemplateRows: ['auto', 'auto'],
};

export const TextListName: CSSObject = {
  color: 'white',
  fontSize: 'md',
  fontFamily: 'heading',
  fontWeight: 'normal',
  textTransform: 'uppercase',
};

export const IconSGM: CSSObject = {
  boxSize: '7',
  display: 'block',
  mr: '2',
  color: 'beta.400',
};

export const TextSpanName = (isVS: boolean): CSSObject => ({
  color: 'beta.800',
  ...(isVS && { color: 'delta.600', mx: '1' }),
  textTranform: 'uppercase',
  fontWeight: 'normal',
});

export const FlexSubTextWrapper: CSSObject = {
  color: 'beta.700',
  fontSize: ['2xs', 'xs'],
  flex: 1,
  fontFamily: 'body',
  fontWeight: 'semibold',
};

export const IconSport: CSSObject = {
  color: 'beta.500',
  boxSize: ['8', '10'],
  bg: 'linear-gradient(180deg,var(--bc-colors-beta-700) 0%,var(--bc-colors-beta-800) 100%)',
  boxShadow:
    '0px 3px 4px 0px var(--bc-colors-blackAlpha-300),0px 0px 0px 1px var(--bc-colors-whiteAlpha-500) inset,0px 0px 0px 1px var(--bc-colors-beta-900)',
  borderRadius: 'xl',
  p: '2',
};

export const IconChevron: CSSObject = {
  color: 'beta.800',
  ml: '0',
  w: 5,
  h: 4,
};

export const FlexEndWrapper: CSSObject = {
  '> div': { gap: '2' },
  mr: '1',
  '.countdown-flex, .timestamp': {
    color: 'beta.800',
    fontWeight: 'bold',
    fontSize: 'xs',
    bg: 'transparent',
    fontFamily: 'body',
  },
};

export const SubTextIcon: CSSObject = {
  '&&': {
    color: 'beta.800',
    boxSize: '5',
  },
};
