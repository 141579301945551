import { CSSObject } from '@chakra-ui/react';
import FadeBg from '@/assets/templebet/images/backgrounds/fadeBgMenu.png';

export const TopNavContainer: CSSObject = {
  mt: '0',
  px: '0',
  pt: ['3', '1.5', '0'],
  pb: '0',
  overflowX: ['scroll', null, 'auto'],
  '::-webkit-scrollbar': {
    width: '0px',
    bg: 'transparent',
    height: '0',
  },
};

export const TopNavWrapper: CSSObject = {
  overflowX: ['scroll', 'scroll', null],
  flexDir: ['row', null, 'column'],
  minW: '160px',
  '&::-webkit-scrollbar': { display: 'none' },
  color: 'white',
  mx: [1, 0],
  height: 'fit-content',
  py: '3px',
  px: ['2', '2', '0'],
  pb: ['0', '0', '3px'],
  bg: [
    'beta.800',
    null,
    `linear-gradient(180deg, var(--bc-colors-beta-200), var(--bc-colors-beta-300)), url(${FadeBg})`,
  ],
  boxShadow: [
    '0px 1px 0px 0px var(--bc-colors-whiteAlpha-200),0px 3px 5px 0px var(--bc-colors-blackAlpha-300) inset',
    '0px 1px 0px 0px var(--bc-colors-whiteAlpha-200),0px 3px 5px 0px var(--bc-colors-blackAlpha-300) inset',
    '0px 2px 2px 0px var(--bc-colors-blackAlpha-300),0px 0px 0px 1px #C8B277 inset',
  ],
  border: ['none', 'none', '2px solid'],
  borderColor: 'white',
  borderRadius: ['0', 'md', 'lg'],
  backgroundPosition: 'top',
  backgroundBlendMode: 'color-burn',
};

export const NavLink = (isActive: boolean): CSSObject => ({
  bg: 'transparent',
  fontFamily: 'accent',
  color: isActive
    ? ['beta.500', 'beta.500', 'delta.600']
    : ['white', 'white', 'beta.800'],
  textShadow: [
    '0px 2px 4px 0px var(--bc-colors-blackAlpha-400)',
    '0px 2px 4px 0px var(--bc-colors-blackAlpha-400)',
    '0px 1px 0px var(--bc-colors-beta-200)',
  ],
  textTransform: 'uppercase',
  fontWeight: 'normal',
  fontSize: 'md',
  lineHeight: 'normal',
  py: ['2', '3'],
  pl: ['2', '4'],
  pr: ['2', '0'],
  mx: '0',
  borderBottom: [
    '2px solid var(--bc-colors-beta-500)',
    '2px solid var(--bc-colors-beta-500)',
    '1px dashed var(--bc-colors-whiteAlpha-500)',
  ],
  borderBottomColor: isActive ? 'beta.500' : ['transparent', 'transparent'],
  borderRight: 'none',
  width: '-webkit-fill-available',
  position: 'relative',
  _after: {
    content: '""',
    position: 'absolute',
    left: '0',
    bottom: '0',
    width: 'full',
    border: ['none', 'none', '1px dashed var(--bc-colors-blackAlpha-50)'],
  },
  _first: {
    borderTopRadius: ['none', null, 'lg'],
  },
  _last: {
    border: 'none',
    _after: {
      display: 'none',
    },
  },
});

export const NavLinkLabel: CSSObject = {
  color: 'beta.800',
  textShadow: '0px 0px 8px #fbc91580',
  border: 'none',
  borderBottom: '4px solid',
  borderBottomColor: 'beta.500',
  filter: 'drop-shadow(0px 1px 0px var(--bc-colors-beta-100))',
  fontSize: 'sm',
  textAlign: 'center',
  fontFamily: 'accent',
  textTransform: 'uppercase',
  minHeight: '7',
  w: '110px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  h: '40px',
  ml: ['2', '5'],
};

export const TopNavLabelWrapper: CSSObject = {
  overflowX: ['scroll', null, 'visible'],
  flexDir: ['row', null, 'column'],
  w: ['95%', '98%'],
  bg: 'blackAlpha.400',
  mx: '2',
  boxShadow:
    '0px 1px 0px 0px var(--bc-colors-whiteAlpha-200),0px 3px 5px 0px var(--bc-colors-blackAlpha-400) inset',
  borderRadius: 'lg',
  mb: '2',
  '&::-webkit-scrollbar': { display: 'none' },
};
