import { CSSObject } from '@chakra-ui/react';

export const FlexBannerWrapper: CSSObject = {
  px: '4',
  py: '2.5',
  m: 'auto',
  h: '12',
  w: 'container.xl',
};

export const FlexWrapper: CSSObject = {
  boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
  bgGradient: 'linear(to-b, alpha.600, alpha.800)',
  bgSize: 'cover',
  borderBottom: '2px solid',
  borderColor: 'beta.500',
};

export const FlexItemsWrapper: CSSObject = {
  mr: '0',
  columnGap: '1',
  '& > div:last-of-type': { mr: '0' },
  minH: 'full',
};

export const CountdownTimer = {
  '&': {
    '.countdown-flex': {
      background: 'transparent',
    },
  },
};

export const LinkItem: CSSObject = {
  w: '40',
  h: 'full',
  py: '2px',
  borderRadius: 'base',
  bgGradient: 'linear(to-b, alpha.300, alpha.500)',
  color: 'white',
  boxShadow:
    '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
  textOverflow: 'ellipsis',
  _hover: {
    bg: 'alpha.200',
    boxShadow:
      '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
  },
  '.chakra-badge.state-inprogress': {
    mr: '1',
    mt: '1',
    borderRadius: 'md',
    maxW: '10',
    fontSize: '9px',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  },

  '.chakra-badge.state-prior': {
    mr: '1',
    mt: '1',
    borderRadius: 'md',
    maxW: '10',
    fontSize: '9px',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  },

  '.chakra-badge.state-ended': {
    mr: '1',
    mt: '1',
    borderRadius: 'md',
    maxW: '10',
    fontSize: '9px',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  },
};

export const RaceNumber: CSSObject = {
  color: 'white',
};
export const FlexDetails: CSSObject = {
  color: 'white',
  display: 'block',
  maxW: '16',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const Icon: CSSObject = {
  color: 'white',
};

export const FlexButtonContainer: CSSObject = {
  bg: 'transparent',
  pos: 'relative',
  p: '0',
  pl: '1',
  gap: '1',
};

export const ButtonChevron: CSSObject = {
  boxSize: '7',
  minW: '7',
  color: 'white',
  p: '0',
  borderRadius: 'md',
  bgGradient: 'linear(to-b, alpha.300, alpha.500)',
  boxShadow:
    '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30)',

  _hover: {
    bg: 'alpha.200',
    boxShadow:
      '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
  },

  '& > svg': { boxSize: '6' },
};
